import React, { useState } from "react";
import { API_BASE_URL } from "../constants";

const Deposit = ({ userData, authenticated }) => {
  // State to store form data
  const [formData, setFormData] = useState({
    amount: "",
  });

  // Loading state for the STK push request
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [resData, setResData] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleStkPush = async (e) => {
    e.preventDefault();
    const apiUrl = `${API_BASE_URL}/api/v1/payments/deposit`;
    const requestData = {
      amount: formData.amount,
    };

    try {
      setLoading(true);

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(requestData),
      });

      const responseData = await response.json();
      if (responseData) {
        setResData(responseData);
      }
      setLoading(false);
      setResponse(responseData);

      if (response.ok) {
        console.log("STK push initiated successfully:", responseData);
      } else {
        console.error("Error initiating STK push:", responseData);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  };

  return (
    <div className="container mt-2 bg-gray-700 pb-64 h-full w-full">
      <div className="flex justify-center">
        <div className="w-full lg:w-1/2 md:w-1/2">
          <div className="card w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow-sm">
            <form className="space-y-6" onSubmit={handleStkPush}>
              <h5 className="text-xl font-medium text-gray-900">
                RECHARGE YOUR ACCOUNT
              </h5>

              <div className="form-group">
                <label htmlFor="amount" className="form-label">
                  Enter Amount
                </label>
                <input
                  type="number"
                  name="amount"
                  id="amount"
                  value={formData.amount}
                  onChange={handleInputChange}
                  placeholder="10000"
                  className="form-control"
                  required
                />
              </div>
              <button
                type="submit"
                className={`btn btn-primary w-full ${
                  loading ? "disabled:opacity-50" : ""
                }`}
                disabled={loading}
              >
                {loading ? "Sending STK Push..." : "Initiate STK Push"}
              </button>
            </form>
            {response && (
              <div
                className={`mt-4 p-3 w-full rounded ${
                  resData.statusCode === 201
                    ? "bg-yellow-300 text-black"
                    : "bg-green-500 text-white"
                }`}
              >
                Status Message: {resData?.statusMessage || "no message"}
                <br />
                Message: {resData?.resMessage || ""}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deposit;
