import React, { useState } from "react";
import "../styles/betcard.css"; // Make sure to create and import the CSS file

const SecondBetCard = ({
  cardType,
  activeTab2,
  handleTabClick2,
  startBet2,
  betAmount2,
  autoCashOut2,
  handleCashOutChange2,
  cashOutMultiplier2,
  setCashOutMultiplier2,
  handleBetAmountChange2,
  handleBetAmountEdit2,
  handleQuickAmountClick2,
}) => {
  const quickAmounts = [100, 200, 500, 10000];
  return (
    <div className="bet-card mt-1">
      <div className="bet-card-header">
        <button
          className={activeTab2 === "Manual" ? "active" : ""}
          onClick={() => handleTabClick2("Manual")}
        >
          Bet
        </button>
        <button
          className={activeTab2 === "Auto" ? "active" : ""}
          onClick={() => handleTabClick2("Auto")}
        >
          Auto
        </button>
      </div>
      <div className="bet-card-content">
        <div className="left-col ">
          {activeTab2 === "Manual" ? (
            <button
              id="create-bet-amt2"
              onClick={startBet2}
              className="bet-button text-gray-900"
            >
              BET {Math.floor(betAmount2)} KES
            </button>
          ) : (
            <button
              id="create-bet-amt2"
              onClick={startBet2}
              className="bet-button text-gray-900"
            >
              BET {Math.floor(betAmount2)} KES
            </button>
          )}
          {activeTab2 === "Auto" && (
            <div className="auto-cashout">
              <label className="auto-cashout-label">
                Auto Cash Out
                <input
                  type="checkbox"
                  checked={autoCashOut2}
                  onChange={handleCashOutChange2}
                />
              </label>
              {autoCashOut2 && (
                <input
                  type="number"
                  value={cashOutMultiplier2}
                  className="text-gray-900 pl-4"
                  onChange={(e) =>
                    setCashOutMultiplier2(parseFloat(e.target.value))
                  }
                  step="0.01"
                />
              )}
            </div>
          )}
        </div>
        <div className="right-col">
          <div className="stake-amount">
            <button onClick={() => handleBetAmountChange2(-10)}>-</button>
            <span
              contentEditable
              suppressContentEditableWarning
              onBlur={handleBetAmountEdit2}
              className="editable-span"
              id="betamount_span2"
            >
              {Math.floor(betAmount2)}
            </span>
            <button onClick={() => handleBetAmountChange2(10)}>+</button>
          </div>
          <div className="quick-amounts">
            {quickAmounts.map((amount) => (
              <button
                key={amount}
                onClick={() => handleQuickAmountClick2(amount)}
              >
                {amount}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondBetCard;
