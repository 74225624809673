import React, { useState, useEffect, useCallback, useRef } from "react";
import "./styles/custom.css";
import { API_BASE_URL, SOCKET_URL } from "./constants";
import socketManager from "./socketManager";

const TABS = {
  ALL: "all",
  MY: "my",
  TOP: "top",
  TOP_RETURNS: "top_returns",
  TOP_CRASH_POINT: "top_crash_point",
};

const BetHistory = ({ userDataMain }) => {
  const socket = socketManager.getSocket();
  const [activeMainTab, setActiveMainTab] = useState(TABS.ALL);
  const [activeSubTab, setActiveSubTab] = useState(null); // To track active sub tab
  const [allBets, setAllBets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const currentUserPhone = userDataMain.userData.phone; // Current user's phone number
  const [usersPlaying, setUsersPlaying] = useState([]);
  const [currentLink, setCurrentLink] = useState("betdata");

  // Define the handlePlayers function to update the state with the received players
  const handlePlayers = (players) => {
    console.log(players);
    // setUsersPlaying(players);
  };

  // Listen for the currentPlayers event
  useEffect(() => {
    socket.on("currentPlayers", handlePlayers);

    // Clean up the socket connection and event listener when the component unmounts
    return () => socket.off("currentPlayers", handlePlayers);
  }, [socket]);

  // Update currentLink based on activeMainTab and activeSubTab
  useEffect(() => {
    switch (activeMainTab) {
      case TABS.MY:
      case TABS.TOP:
      case TABS.TOP_RETURNS:
      case TABS.TOP_CRASH_POINT:
        setCurrentLink("userbets");
        break;
      default:
        setCurrentLink("betdata");
        break;
    }
  }, [activeMainTab, activeSubTab]);

  useEffect(() => {
    const fetchBets = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/v1/auth/${currentLink}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setAllBets(data);
        setUsersPlaying(data.length);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchBets(); // Initial fetch
    const intervalId = setInterval(fetchBets, 1000); // Fetch every 1 second

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [currentLink]);

  const myBets = allBets.filter((bet) => bet.user_phone === currentUserPhone);

  const topReturnsBets = [...allBets]
    .sort((a, b) => b.returns - a.returns)
    .slice(0, 3);

  const topCrashPointBets = [...allBets]
    .sort((a, b) => b.crash_point - a.crash_point)
    .slice(0, 3);

  const getFilteredBets = () => {
    switch (activeMainTab) {
      case TABS.MY:
        return myBets;
      case TABS.TOP:
        return activeSubTab === TABS.TOP_CRASH_POINT
          ? topCrashPointBets
          : topReturnsBets;
      case TABS.TOP_RETURNS:
        return topReturnsBets;
      case TABS.TOP_CRASH_POINT:
        return topCrashPointBets;
      default:
        return allBets;
    }
  };

  const getHighestValue = () => {
    if (activeSubTab === TABS.TOP_RETURNS) {
      return Math.max(...topReturnsBets.map((bet) => bet.returns));
    } else if (activeSubTab === TABS.TOP_CRASH_POINT) {
      return Math.max(...topCrashPointBets.map((bet) => bet.crash_point));
    }
    return null;
  };

  const filteredBets = getFilteredBets();
  const highestValue = getHighestValue();

  const MainTabButton = ({ label, tab }) => (
    <div
      className={`custom-tap ${
        activeMainTab === tab ? "bg-secondary font-weight-bold" : ""
      }`}
      onClick={() => {
        setActiveMainTab(tab);
        setActiveSubTab(null); // Reset sub tab when main tab is clicked
      }}
      style={{ cursor: "pointer" }}
    >
      {label}
    </div>
  );

  const SubTabButton = ({ label, tab }) => (
    <div
      className={`custom-tap ${
        activeSubTab === tab ? "bg-secondary font-weight-bold" : ""
      }`}
      onClick={() => setActiveSubTab(tab)}
      style={{ cursor: "pointer" }}
    >
      {label}
    </div>
  );

  const obfuscatePhoneNumber = (phone) => {
    if (phone.length >= 7) {
      return `${phone.slice(0, 2)}***${phone.slice(-1)}`;
    }
    return phone;
  };

  return (
    <div className="container mt-3 bg-dark main_hist_card">
      <div className="mt-4">
        <div className="">
          <div className="flex justify-center">
            <div className="flex custom-tap-card text-white">
              <MainTabButton label="All Bets" tab={TABS.ALL} />
              <MainTabButton label="My Bets" tab={TABS.MY} />
              <MainTabButton label="Top Bets" tab={TABS.TOP} />
            </div>
          </div>
          {activeMainTab === TABS.TOP && (
            <div className="flex justify-center mt-3">
              <div className="flex custom-tap-card text-white">
                <SubTabButton label="Huge Wins" tab={TABS.TOP_RETURNS} />
                <SubTabButton
                  label="Top Multipliers"
                  tab={TABS.TOP_CRASH_POINT}
                />
              </div>
            </div>
          )}

          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <div className="items-card">
              {highestValue !== null && (
                <div className="mt-1 text-light flex justify-center">
                  <div className="text-center">
                    {activeSubTab === TABS.TOP_RETURNS ? (
                      <div className="high_value">
                        KES.{(highestValue * 18658).toLocaleString()}
                      </div>
                    ) : (
                      <div className="high_value">{highestValue * 18658}X</div>
                    )}
                  </div>
                </div>
              )}
              <div className="px-4 py-1 text-light flex justify-between">
                <div>
                  ALL BETS:
                  <br />
                  <p className="badge bg-info text-dark" id="usersbetting">
                    {Math.ceil(usersPlaying)}
                  </p>
                </div>
                <div className="texthead mt-2 pt-1">
                  <p className="btn btn-sm btn-outline-light p-0 m-0 px-2">
                    <i className="bi bi-clock-history"> </i>previous hand
                  </p>
                </div>
              </div>
              <div className="text-light grid grid-cols-5 gap-2">
                <div className="texthead">User</div>
                <div className="texthead">Hash</div>
                <div className="texthead">Amount</div>
                <div className="texthead">Multiplier</div>
                <div className="texthead">Cash Out(kes)</div>
              </div>
              <div className="overflow-card">
                {filteredBets.map((bet, index) => (
                  <div
                    key={index}
                    className={`grid grid-cols-5 gap-2 ${
                      bet.status === "win"
                        ? "single-item-won"
                        : "single-item-loss"
                    }`}
                  >
                    <div className="flex items-center">
                      <img src="/user.png" alt="user" className="w-5 mr-1" />
                      {obfuscatePhoneNumber(bet.user_phone)}
                    </div>
                    <div>{bet.game_hash}</div>
                    <div>{bet.amount}</div>
                    <div>{bet.crash_point}</div>
                    <div>{bet.returns}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BetHistory;
