import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  CheckCircleIcon,
  XCircleIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";

const Transactions = ({ userData }) => {
  const UserId = userData ? userData.id : "5676"; // Default ID for testing
  const Userphone = userData ? userData.phone : "0769759910";
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://admin.heavywins.com/public/admin/api/v1/transactions?user_id=${Userphone}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch transactions");
        }

        const data = await response.json();
        console.log(data);
        setTransactions(data);
      } catch (error) {
        console.error("Error fetching transactions:", error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [UserId, Userphone]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <Spinner animation="border" role="status" className="text-primary fs-1">
          <span className="sr-only"></span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="container my-2 mx-auto mt-10 p-6 bg-white shadow-lg rounded-lg">
      <h2 className="mb-6 text-primary font-bold text-2xl text-center">
        Your Transactions Overview
      </h2>
      {transactions.length > 0 ? (
        <ul className="space-y-4">
          {transactions.map((trx) => (
            <li
              key={trx.date}
              className="flex justify-between items-center p-4 border-b last:border-b-0"
            >
              <div className="flex flex-col text-left">
                <p className="text-gray-600 font-semibold">{trx.type}</p>
                <p className="text-gray-500">MPESA</p>
              </div>
              <div className="text-right">
                <p className="text-gray-500 mb-1">
                  <ClockIcon className="inline h-4 w-4 mr-1" />
                  {new Date(trx.date).toLocaleString()}
                </p>
                <p className="font-bold">
                  {trx.type === "cashout" ? (
                    <span className="text-red-500">- {trx.amount}</span>
                  ) : (
                    <span className="text-green-500">+ {trx.amount}</span>
                  )}
                </p>
                <p>
                  {trx.status === "success" ? (
                    <span className="text-green-500">
                      <CheckCircleIcon className="h-5 w-5 inline" />{" "}
                      <b>{trx.status}</b>
                    </span>
                  ) : (
                    <span className="text-red-500">
                      <XCircleIcon className="h-5 w-5 inline" />{" "}
                      <b>{trx.status}</b>
                    </span>
                  )}
                </p>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div className="text-center text-gray-500">
          <p>No Transactions yet</p>
        </div>
      )}
    </div>
  );
};

export default Transactions;
