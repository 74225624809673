import React, { useState, useEffect } from "react";

const CustomAlert = ({
  handleClose,
  variant,
  header,
  message,
  autoDismiss,
}) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (autoDismiss && show) {
      setTimeout(() => {
        //alert(autoDismiss);
        setShow(false);
        handleClose(); // Call the handleClose function after auto-dismiss
      }, 2000); // Auto-dismiss after 5 seconds (5000 milliseconds)
    }
  }, [autoDismiss, handleClose, show]);

  const getVariantStyles = () => {
    switch (variant) {
      case "success":
        return "bg-green-100 text-green-800 border border-green-300";
      case "danger":
        return "bg-red-100 text-red-800 border border-red-300";
      case "warning":
        return "bg-yellow-100 text-yellow-800 border border-yellow-300";
      case "info":
        return "bg-blue-100 text-blue-800 border border-blue-300";
      default:
        return "bg-gray-100 text-gray-800 border border-gray-300";
    }
  };

  return (
    <div className="relative z-50">
      {show && (
        <div
          className={`p-4 rounded-md shadow-md relative ${getVariantStyles()}`}
        >
          {/* Close Icon */}
          <button
            className="absolute top-2 right-2 text-lg text-gray-800 hover:text-gray-500 focus:outline-none"
            onClick={() => {
              setShow(false);
              handleClose();
            }}
          >
            &times;
          </button>

          {/* Alert Content */}
          {header && <h4 className="text-lg font-semibold mb-2">{header}</h4>}
          {message && <p className="text-sm">{message}</p>}
        </div>
      )}
    </div>
  );
};

export default CustomAlert;
