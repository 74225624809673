// src/App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import {
  HomeIcon,
  PlusCircleIcon,
  ArrowDownIcon,
  UserCircleIcon,
  PowerIcon,
  MenuIcon,
  PlusIcon,
  ArrowDownTrayIcon,
  UserIcon,
  Bars3Icon,
  InboxIcon,
  UsersIcon,
  PlayIcon,
} from "@heroicons/react/24/outline";
import "./App.css";
// src/index.js or src/App.js
import "./styles/tailwind.css";
import Aviator from "./screens/Aviator";
import Deposit from "./screens/Deposit";
import Withdraw from "./screens/Withdraw";
import Profile from "./screens/Profile";
import Login from "./screens/Login";
import Register from "./screens/Register";
import { API_BASE_URL } from "./constants";
import Alert from "./components/Alert";
import Swal from "sweetalert2";
import Transactions from "./screens/Transactions";
import TeamsPage from "./screens/TeamsPage";

function App() {
  const [referrerId, setReferrerId] = useState("1");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [theme, setTheme] = useState("light");

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleSidebarClose = () => {
    setSidebarOpen(false);
  };

  const toggleColorTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    console.log("Current theme:", newTheme);
    setTheme(newTheme);
  };

  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);

  const handleCloseLogin = () => setShowLogin(false);
  const handleShowLogin = () => setShowLogin(true);

  const handleCloseRegister = () => setShowRegister(false);
  const handleShowRegister = () => setShowRegister(true);

  const [showError] = useState(false);
  const [userData, setUserData] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const [formData, setFormData] = useState({
    phoneNumber: "",
    password: "",
    confirm_password: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  useEffect(() => {
    // Function to prompt user before reload

    // Set initial session values or reset to false
    sessionStorage.setItem("haveBet", false);
    sessionStorage.setItem("haveBet2", "false");
    sessionStorage.setItem("hasProcessed", "false");
    sessionStorage.setItem("gameId", "e1sTUxDqUX");
    sessionStorage.setItem("hasProcessed2", "false");
    sessionStorage.setItem("haveBet", "false");

    // Clean up function to reset session values to false on unmount
    return () => {
      // Reset session values to false
      sessionStorage.setItem("haveBet2", "false");
      sessionStorage.setItem("hasProcessed", "false");
      sessionStorage.setItem("hasProcessed2", "false");
      sessionStorage.setItem("haveBet", "false");
      // 'gameId' is set to a default value on mount, not reset here as per your original request

      // Remove the event listener when the component unmounts
      window.onbeforeunload = null;
    };
  }, []);
  useEffect(() => {
    // Check if user is authenticated on component mount
    const checkAuth = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/v1/auth/check`, {
          credentials: "include", // Include cookies in the request
        });
        const data = await response.json();
        setAuthenticated(data.authenticated);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    checkAuth();
  }, []);

  useEffect(() => {
    // Get the current URL
    const currentUrl = window.location.href;
    console.log("Current URL:", currentUrl);

    // Extract the ref parameter if it exists
    const urlParams = new URLSearchParams(window.location.search);
    const userRefId = urlParams.get("ref");
    setReferrerId(userRefId);
    console.log("Referrer ID:", userRefId);
  }, []);

  const handleRegisterSubmit = async (event) => {
    //get user details
    event.preventDefault();

    // Get input values from the form

    if (formData.confirm_password !== formData.password) {
      Swal.fire({
        icon: "error",
        title: "Registration Failed!",
        text: "Password Mismatched",
      });
    }

    //create user object
    const user = formData;

    //console.log(user);
    //send details to backend
    try {
      // Send the user details to the server using a POST request
      console.log(referrerId);
      const response = await fetch(
        `${API_BASE_URL}/api/v1/auth/register?ref=${referrerId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(user),
        }
      );

      // Check if the request was successful
      if (response.status === 200) {
        const data = await response.json();
        // Handle successful registration response from the server
        console.log("Registration successful:", data);
        handleCloseRegister();
        Swal.fire({
          icon: "success",
          title: "Registration Success",
          text: data.message,
        });
        // Optionally, you can redirect the user to a different page or display a success message
      } else {
        // Handle errors if the request was not successful
        const data = await response.json();
        console.log(data);

        Swal.fire({
          icon: "error",
          title: "Registration Failed",
          text: data.message,
        });

        // Optionally, display an error message to the user
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Failed!",
        text: error,
      });
      console.error("Error:", error);
      // Handle any unexpected errors
    }
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/auth/login`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phone, password }),
      });

      if (response.status === 200) {
        handleCloseLogin();
        // Login successful
        setAuthenticated(true);
        handleCloseLogin();

        Swal.fire({
          icon: "success",
          title: "LoggedIn!",
          text: "start playing",
        });
        const data = await response.json();
        console.log(data);

        // Redirect user to dashboard or other authenticated routes
      } else if (response.status === 201) {
        setAuthenticated(false);
        const data = await response.json();
        Swal.fire({
          icon: "error",
          title: "Login Failed!",
          text: data.message,
        });
      } else {
        // Login failed
        setAuthenticated(false);
        const data = await response.json();
        console.log(data);
        Swal.fire({
          icon: "error",
          title: "Login Failed!",
          text: data.message,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        title: "Login Failed!",
        text: "An error occurred while logging in",
      });
    }
  };
  const handleLogout = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/auth/logout`, {
        method: "POST",
        credentials: "include", // Include cookies in the request
      });

      if (response.ok) {
        setAuthenticated(false);
        Swal.fire({
          icon: "success",
          title: "Logged Out",
          text: "Logout successful",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    // Function to fetch user data
    const fetchUserData = async () => {
      if (authenticated) {
        try {
          const response = await fetch(
            `${API_BASE_URL}/api/v1/auth/user/data`,
            {
              method: "GET",
              credentials: "include", // Include cookies in the request
            }
          );

          if (response.status === 200) {
            const userData = await response.json();

            setUserData(userData); // Update userData state with fetched data
          } else if (response.status === 401) {
            console.log("Fetched...............", "denied");
          } else {
            console.log("Error fetching user data");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    };

    // Call fetchUserData when the component mounts
    fetchUserData();
    //fetch in intervals
    // Update user balance at intervals of 5 seconds
    const intervalId = setInterval(fetchUserData, 1000);

    // Clean up function to clear the interval when the component unmounts or the dependency changes
    return () => clearInterval(intervalId);
  }, [authenticated]);

  const appClass = theme === "light" ? "main-app-light" : "main-app-dark";

  return (
    <Router>
      <div className={appClass}>
        {showError && (
          <Alert message="Error: Passwords do not match!" type="error" />
        )}
        <div
          className={`w-full mb-0 pb-0 ${
            theme === "light"
              ? "text-black bg-gray-100"
              : "text-white bg-gray-600"
          }  flex items-center`}
        >
          <div className="nav-menu-icon">
            {/* start of menu */}
            <div className="navbar">
              <div className="navbar-start">
                <div className="dropdown">
                  <div
                    tabIndex={0}
                    role="button"
                    className="btn btn-ghost btn-circle"
                  >
                    {authenticated ? (
                      <Bars3Icon className="h-5 w-5" />
                    ) : (
                      <p>HW</p>
                    )}
                  </div>
                  <ul
                    tabIndex={50}
                    className="menu menu-lg dropdown-content bg-gray-300 z-[50] rounded-sm w-84 h-100 p-2 shadow"
                  >
                    <li>
                      <a
                        className="flex items-center py-2 px-4 hover:bg-gray-200"
                        href="/"
                      >
                        <span className="px-2">
                          <HomeIcon className="h-5 w-5" />
                        </span>
                        Home
                      </a>
                    </li>
                    <li>
                      <a
                        className="flex items-center py-2 px-4 hover:bg-gray-200"
                        href="/deposit"
                      >
                        <span className="px-2">
                          <PlusIcon className="h-5 w-5" />
                        </span>
                        Deposit
                      </a>
                    </li>
                    <li>
                      <a
                        className="flex items-center py-2 px-4 hover:bg-gray-200"
                        href="/withdraw"
                      >
                        <span className="px-2">
                          <ArrowDownTrayIcon className="h-5 w-5" />
                        </span>
                        Withdraw
                      </a>
                    </li>
                    <li>
                      <a
                        className="flex items-center py-2 px-4 hover:bg-gray-200"
                        href="/profile"
                      >
                        <span className="px-2">
                          <UserIcon className="h-5 w-5" />
                        </span>
                        Profile
                      </a>
                    </li>
                    <li>
                      <a
                        className="flex items-center py-2 px-4 hover:bg-gray-200"
                        href="/transactions"
                      >
                        <span className="px-2">
                          <PlayIcon className="h-5 w-5" />
                        </span>
                        Transactions
                      </a>
                    </li>
                    <li>
                      <a
                        className="flex items-center py-2 px-4 hover:bg-gray-200"
                        href="/teams"
                      >
                        <span className="px-2">
                          <UsersIcon className="h-5 w-5" />
                        </span>
                        Teams
                      </a>
                    </li>
                    <li>
                      <a
                        className="flex items-center py-2 px-4 hover:bg-gray-200"
                        href="https://admin.heavywins.com/public/admin/support"
                      >
                        <span className="px-2">
                          <InboxIcon className="h-5 w-5" />
                        </span>
                        Support
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* end of menu */}

            <i
              isOpen={sidebarOpen}
              authenticated={authenticated}
              handleShowLogin={handleShowLogin}
              toggleSidebar={toggleSidebar}
            ></i>
          </div>
          {authenticated ? (
            <div className="w-full flex justify-end gap-2 mt-3 pb-1 pt-0">
              <button
                className="bg-yellow-500 hover:bg-yellow-700 text-white text-sm font-semibold py-2 px-4 rounded flex items-center"
                onClick={handleLogout}
              >
                Logout <i className="fas fa-switch h-5 w-5 ml-2" />
              </button>
              <button
                onClick={toggleColorTheme}
                className={`text-sm font-semibold py-1  px-4 rounded flex items-center ${
                  theme === "light"
                    ? "bg-white text-black"
                    : "bg-gray-800 text-white"
                }`}
              >
                {theme === "light" ? (
                  <i className="h-5 w-5 fas fa-sun" />
                ) : (
                  <i className="h-5 w-5 fas fa-moon" />
                )}
              </button>
            </div>
          ) : (
            <div className="w-full flex justify-end gap-2 mt-3 pb-1 pt-0">
              <button
                onClick={handleShowLogin}
                className={`btn btn-primary btn-info btn-sm hover:bg-blue-700 font-bold rounded flex items-center ${
                  theme === "light" ? " text-black" : "text-dark"
                }`}
              >
                Login
              </button>
              <button
                onClick={handleShowRegister}
                className="btn btn-accent btn-sm hover:bg-green-700  text-dark text-sm font-bold  px-4 rounded"
              >
                Register
              </button>
              <button
                onClick={toggleColorTheme}
                className={`text-sm font-semibold mr-2 p-2 rounded flex items-center ${
                  theme === "light" ? " text-black" : " text-white"
                }`}
              >
                {theme === "light" ? (
                  <i className="fas fa-moon" />
                ) : (
                  <i className=" fas fa-sun" />
                )}
              </button>
            </div>
          )}
        </div>

        <div
          className={`nav-content mt-0 mb-0 ${
            sidebarOpen ? "nav-sidebar-open" : ""
          }`}
        ></div>

        <Routes>
          <Route
            path="/"
            element={
              <Aviator
                userData={userData}
                toggleSidebarClose={toggleSidebarClose}
                authenticated={authenticated}
              />
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/aviator"
            element={<Aviator toggleSidebar={toggleSidebar} />}
          />
          <Route
            path="/deposit"
            element={
              <Deposit userData={userData} authenticated={authenticated} />
            }
          />
          <Route
            path="/withdraw"
            element={
              <Withdraw userData={userData} authenticated={authenticated} />
            }
          />
          <Route
            path="/profile"
            element={
              <Profile userData={userData} authenticated={authenticated} />
            }
          />
          <Route
            path="/transactions"
            element={
              <Transactions userData={userData} authenticated={authenticated} />
            }
          />
          <Route
            path="/teams"
            element={
              <TeamsPage userData={userData} authenticated={authenticated} />
            }
          />
          <Route path="/logout" element={<Login />} />
        </Routes>
        {/* add modals */}

        {showLogin && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div className="w-full max-w-md p-4 bg-gray-800 rounded-lg shadow-lg">
              <div className="flex justify-between items-center mb-4">
                <h4 className="text-xl font-semibold text-white">Login</h4>
                <button
                  className="text-white text-lg font-bold"
                  onClick={handleCloseLogin}
                >
                  ×
                </button>
              </div>
              <hr className="border-gray-600 mb-4" />
              <form onSubmit={handleLoginSubmit}>
                <div className="mb-4">
                  <label className="block text-white mb-2">Phone Number</label>
                  <input
                    type="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Enter phone number"
                    className="w-full px-3 py-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white mb-2">Password</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    className="w-full px-3 py-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
                <button
                  type="submit"
                  className="w-full py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Login
                </button>
              </form>
            </div>
          </div>
        )}

        {/* Register Modal */}
        {showRegister && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div className="w-full max-w-md p-4 bg-gray-800 rounded-lg shadow-lg z-60">
              <div className="flex justify-between items-center mb-4">
                <h4 className="text-xl font-semibold text-white">Register</h4>
                <button
                  className="text-white text-lg font-bold"
                  onClick={handleCloseRegister}
                >
                  ×
                </button>
              </div>
              <hr className="border-gray-600 mb-4" />
              <form onSubmit={handleRegisterSubmit}>
                <div className="mb-4">
                  <label className="block text-white mb-2">Phone Number</label>
                  <input
                    id="phoneNumber"
                    name="phoneNumber"
                    type="tel"
                    value={formData.phone}
                    onChange={handleInputChange}
                    placeholder="Enter phone number"
                    className="w-full px-3 py-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white mb-2">Password</label>
                  <input
                    id="password"
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    placeholder="Password"
                    className="w-full px-3 py-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-white mb-2">
                    Repeat Password
                  </label>
                  <input
                    id="confirm_password"
                    name="confirm_password"
                    type="password"
                    value={formData.confirm_password}
                    onChange={handleInputChange}
                    placeholder="Confirm Password"
                    className="w-full px-3 py-2 bg-gray-700 text-white rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </div>
                <button
                  type="submit"
                  className="w-full py-2 bg-indigo-500 text-white rounded hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Register
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;
