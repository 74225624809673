import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Spinner } from "react-bootstrap";
import {
  PhoneIcon,
  ClockIcon,
  WalletIcon,
  CreditCardIcon,
  DocumentTextIcon,
  UsersIcon,
  ClipboardIcon,
} from "@heroicons/react/24/outline";
import Game from "../Game";

const Dashboard = ({ userData, authenticated }) => {
  let Userphone = userData ? (userData.phone ? userData.phone : "07") : "07";
  let UserId = userData ? (userData.id ? userData.id : "5676") : "234";

  const [userDataFetched, setUserDataFetched] = useState(null);
  const [loading, setLoading] = useState(true);
  const referralLink = "https://heavywins.com"; ///user/data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://heavywins.com/api/v1/auth/user/profile`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer `,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        const data = await response.json();
        setUserDataFetched(data);
        console.log(data);
      } catch (error) {
        console.error("Error fetching user data:", error.message);
        // Handle error state if needed
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const totalGames = userDataFetched ? userDataFetched.gamesCount : 0;
  const totalCashouts =
    userDataFetched && userDataFetched.cashouts
      ? userDataFetched.cashouts.amount
      : 0;
  const balance =
    userDataFetched && userDataFetched.details
      ? userDataFetched.details.balance
      : 0;

  const copyReferralLink = () => {
    // Your existing copy-to-clipboard logic
    alert("copied");
  };

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center vh-100">
        <Spinner animation="border" role="status" className="text-primary fs-1">
          <span className="sr-only"></span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="container mt-5 my-2">
      <h2 className="mb-4 text-white font-bold text-xl">
        Your Profile Overview
      </h2>
      <div className="flex flex-wrap -mx-2">
        {/* User Information Card */}
        <div className="w-full md:w-1/2 px-2 mb-4">
          <div className="bg-white border border-blue-500 rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow duration-300">
            <h5 className="mb-2 text-gray-700 font-semibold text-lg">
              User Information
            </h5>
            <p className="flex items-center text-gray-600">
              <PhoneIcon className="mr-2 h-6 w-6 text-blue-500" />
              Phone: <span className="ml-1 font-bold">{Userphone}</span>
            </p>
          </div>
        </div>

        {/* Investment Portfolio Card */}
        <div className="w-full md:w-1/2 px-2 mb-4">
          <div className="bg-white border border-green-500 rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow duration-300">
            <h5 className="mb-2 text-gray-700 font-semibold text-lg">
              Betting Portfolio
            </h5>
            <p className="flex items-center text-gray-600">
              <ClockIcon className="mr-2 h-6 w-6 text-green-500" />
              Total Bets: <span className="ml-1 font-bold">{totalGames}</span>
            </p>
            <p className="flex items-center text-gray-600">
              <WalletIcon className="mr-2 h-6 w-6 text-green-500" />
              Account Balance:{" "}
              <span className="ml-1 font-bold">KES. {balance}</span>
            </p>
            <p className="flex items-center text-gray-600">
              <CreditCardIcon className="mr-2 h-6 w-6 text-green-500" />
              Total Cashouts:{" "}
              <span className="ml-1 font-bold">KES. {totalCashouts}</span>
            </p>
          </div>
        </div>

        {/* Links Card */}
        <div className="w-full md:w-1/2 px-2 mb-4">
          <div className="bg-white border border-purple-500 rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow duration-300">
            <h5 className="mb-2 text-gray-700 font-semibold text-lg">
              Quick Links
            </h5>
            <p className="flex items-center text-gray-600 hover:text-purple-600 transition-colors duration-300">
              <DocumentTextIcon className="mr-2 h-6 w-6 text-purple-500" />
              <Link
                to="/transactions"
                className="text-purple-500 underline hover:no-underline"
              >
                View Transactions
              </Link>
            </p>
            <p className="flex items-center text-gray-600 hover:text-purple-600 transition-colors duration-300">
              <UsersIcon className="mr-2 h-6 w-6 text-purple-500" />
              <Link
                to="/teams"
                className="text-purple-500 underline hover:no-underline"
              >
                View Teams
              </Link>
            </p>
            <hr className="my-4" />
            <p className="text-center text-gray-700 font-semibold">
              Invite Friends To Earn Free Bets!
            </p>
            <CopyToClipboard
              text={`${referralLink}?ref=${UserId}`}
              onCopy={copyReferralLink}
            >
              <p className="flex items-center cursor-pointer text-gray-600 hover:text-blue-600 transition-colors duration-300">
                <ClipboardIcon className="mr-2 h-6 w-6 text-blue-500" />
                <button className="text-blue-500 underline p-0">
                  Copy My Referral Link
                </button>
              </p>
            </CopyToClipboard>
            <p className="mt-2">
              <input
                readOnly
                type="text"
                value={`${referralLink}?ref=${UserId}`}
                className="form-control form-control-sm bg-gray-100 border border-gray-300 rounded p-2"
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
