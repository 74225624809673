// src/Aviator.js
import React, { useState } from "react";
import GameHeader from "../components/GameHeader";
import Game from "../Game";
import BetHistory from "../BetHistory";

const Aviator = ({ userData, authenticated, toggleSidebarClose }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [canAnimate, setCanAnimate] = useState(true);

  const toggleMenu = () => {
    console.log("hey");
    setIsMenuVisible(!isMenuVisible);
  };

  const handleToggle = () => {
    setCanAnimate((prevState) => !prevState);
  };

  //toggleAnimations;

  const toggleMenuClose = () => {
    setIsMenuVisible(false);
  };

  return (
    <div className="main-container mt-0 pt-0" onClick={toggleSidebarClose}>
      <div className="child-container m-0 text-center w-full">
        <GameHeader
          toggleMenu={toggleMenu}
          isMenuVisible={isMenuVisible}
          userDataMain={{ userData, authenticated }}
          onToggle={handleToggle}
          canAnimate={canAnimate}
        />
        <div
          className="flex flex-col lg:flex-row m-0 rounded shadow main_aviator_row"
          onClick={toggleMenuClose}
        >
          <div className="w-full lg:w-2/3 mt-0 pt-0">
            <Game
              userDataMain={{ userData, authenticated }}
              canAnimate={canAnimate}
            ></Game>
          </div>
          <div className="w-full lg:w-1/3 mt-0 pt-0">
            <BetHistory userDataMain={{ userData }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aviator;
