import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

const TeamsPage = ({ userData }) => {
  const UserId = userData ? userData.id : "1"; // Default ID for testing
  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://admin.heavywins.com/public/admin/api/v1/teams?user_id=${UserId}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch teams");
        }

        const data = await response.json();
        setTeams(data);
      } catch (error) {
        console.error("Error fetching teams:", error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [UserId]);

  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center vh-100">
        <Spinner animation="border" role="status" className="text-primary fs-1">
          <span className="sr-only"></span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <h2 className="mb-4 text-primary font-bold text-xl">Your User Teams</h2>
      {teams.length > 0 ? (
        <div className="container mx-auto p-4">
          <table className="min-w-full bg-white border rounded-lg shadow-md">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b text-left">Phone</th>
                <th className="py-2 px-4 border-b text-left">Balance</th>
                <th className="py-2 px-4 border-b text-left">Bonus</th>
                <th className="py-2 px-4 border-b text-left">Status</th>
              </tr>
            </thead>
            <tbody>
              {teams.map((team) => (
                <tr key={team.id}>
                  <td className="py-2 px-4 border-b">{team.phone}</td>
                  <td className="py-2 px-4 border-b">
                    {team.balance.toFixed(2)}
                  </td>
                  <td className="py-2 px-4 border-b text-green-500">
                    {team.bonus ? team.bonus.toFixed(2) : "0.00"}
                  </td>
                  <td className="py-2 px-4 border-b">{team.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-center">
          <p>No Teams found</p>
        </div>
      )}
    </div>
  );
};

export default TeamsPage;
