import React, { useState, useEffect, useRef, canAnimate } from "react";
import "./styles/game.css";
import "./styles/radial.css";
import "./styles/custom.css";
import Modal from "./components/Modal";
import BetCard from "./components/BetCard";
import CustomAlert from "./components/CustomAlert";
import { API_BASE_URL, SOCKET_URL } from "./constants";
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import SecondBetCard from "./components/SecondBetCard";
import useStore from "./zustandStore";
import useStore2 from "./zustandStore2";
import socketManager from "./socketManager";
import { useLocation } from "react-router-dom";

// Registering the MotionPathPlugin for GSAP
gsap.registerPlugin(MotionPathPlugin);

const Game = ({ userDataMain, authenticated, canAnimate }) => {
  const socket = socketManager.getSocket();
  const location = useLocation();
  // Accessing state and setter from useStore hook
  const { sethaveBet, setHasProcessed } = useStore();
  const { haveBet2, sethaveBet2, setHasProcessed2 } = useStore2();

  const canSendRequestRef = useRef(true);
  const canSendRequestRef2 = useRef(true);

  const [cardWidth, setCardWidth] = useState(null);

  useEffect(() => {
    const updateCardWidth = () => {
      const width = document.getElementById("game_card")?.offsetWidth;
      setCardWidth(width);
    };

    updateCardWidth(); // Initial update
    // Update card width when window is resized
    window.addEventListener("resize", updateCardWidth);

    // CleanupF
    return () => {
      window.removeEventListener("resize", updateCardWidth);
    };
  }, []);
  const cardRef = useRef(null);
  const cardHeight = 250;
  const pathRef = useRef(null);
  const planeRef = useRef(null);
  const trailRef = useRef(null);
  const planeAnimationRef = useRef(null);
  const danceAnimationRef = useRef(null);
  const trailAnimationRef = useRef(null);
  const [planeVisible, setPlaneVisible] = useState(true);
  const [flewAway, setFlewAway] = useState(false);

  // State to manage animation playback
  const [animationPaused, setAnimationPaused] = useState(true);
  //setTimeTake
  const [timeToTake, setTimeTake] = useState(10);
  // State to store the path for shading
  const [shadedPath, setShadedPath] = useState("");

  const [monitoringStatus, setMonitoringStatus] = useState(20);
  const threshold = 1000; // Example threshold for monitoring, adjust as needed

  //start of plane animation=========================
  const [dancingShadedPath, setDancingShadedPath] = useState("");
  const [lineConnectingPath, setLineConnectingPath] = useState("");

  useEffect(() => {
    const path = pathRef.current;
    const plane = planeRef.current;
    const trail = trailRef.current;
    const length = path.getTotalLength();

    if (canAnimate) {
      // Initialize the plane animation
      planeAnimationRef.current = gsap.to(plane, {
        paused: true,
        duration: 20, // Time to take
        repeat: 1,
        yoyo: true,
        motionPath: {
          path: path,
          align: path,
          alignOrigin: [0.5, 0.5],
          autoRotate: false,
        },
        ease: "none",
        onComplete: () => {},
        onUpdate: () => {
          const planePosition =
            (planeAnimationRef.current.time() /
              planeAnimationRef.current.duration()) *
            length;

          if (planePosition >= length && !animationPaused) {
            planeAnimationRef.current.restart();
          }

          const point = path.getPointAtLength(planePosition);
          const x = point.x;
          const y = point.y;
          updateShadedArea(x, y); // Update the shaded area with the current position

          if (planeAnimationRef.current.time() > 18) {
            startDancing();
            planeAnimationRef.current.pause();
            // Remove the trail and initial shaded area
            if (trailRef.current) {
              gsap.to(trailRef.current, {
                opacity: 0,
                duration: 0.5, // Smooth transition
                onComplete: () => {
                  trailRef.current.style.display = "none";
                },
              });
            }

            // Remove the initial shaded area
            setShadedPath("");
          }
        },
      });

      // Initialize animation for the trail
      trailAnimationRef.current = gsap.to(trail, {
        paused: true,
        duration: 20, // Duration should match the plane's
        repeat: -1,
        yoyo: true,
        motionPath: {
          path: path,
          align: path,
          alignOrigin: [0.5, 0.5],
          autoRotate: false,
        },
        ease: "none",
        delay: -0.5,
      });

      if (flewAway) {
        setShadedPath("");
        setDancingShadedPath("");
        gsap.to(planeRef.current, {
          duration: 1,
          x: cardWidth * 1.5,
          y: -cardHeight,
          onComplete: () => {
            setPlaneVisible(false);
          },
        });
      }

      const updateShadedArea = (x, y) => {
        const controlPointX = x / 2;
        const controlPointY = y + 50; // Adjust for curvature

        // Create a path with a curved line and no vertical line
        setShadedPath(
          `M0,${cardHeight} Q${controlPointX},${controlPointY} ${x},${y} L${x},${cardHeight}`
        );
      };

      const startDancing = () => {
        const danceHeight = cardHeight * 0.25; // 5% of card height
        const danceWidth = 50; // 5% of card height

        // Hide the previous trail
        if (trailRef.current) {
          gsap.to(trailRef.current, {
            opacity: 0,
            duration: 0.5, // Smooth transition
            onComplete: () => {
              // Hide the element completely
              trailRef.current.style.display = "none";
            },
          });
        }

        // Create a new GSAP timeline for the dancing animation
        danceAnimationRef.current = gsap.timeline({
          repeat: -1,
          yoyo: true,
          onUpdate: () => {
            // Get the current position of the plane relative to the SVG
            const planeBBox = planeRef.current.getBoundingClientRect();
            const svgBBox = cardRef.current.getBoundingClientRect();
            const x = planeBBox.left - svgBBox.left + planeBBox.width / 2;
            const y = planeBBox.top - svgBBox.top + planeBBox.height / 2;

            // Update the dancing shaded area to connect the origin and the current position
            updateDancingShadedArea(x, y);
            // Update the line connecting path
            // updateLineConnectingPath(x, y);
          },
        });

        // Animate the plane and trail together
        danceAnimationRef.current.to([planeRef.current], {
          y: `-=${danceHeight}`,
          x: `-=${danceWidth}`,
          duration: 2,
          ease: "power1.inOut",
        });
      };

      const updateDancingShadedArea = (x, y) => {
        // Define control points for a smooth curve
        const controlPointX = x / 2;
        const controlPointY = y + 50; // Adjust curvature as needed
        // Create a carved line with a quadratic Bezier curve
        setDancingShadedPath(
          `M0,${cardHeight} Q${controlPointX},${controlPointY} ${x},${y} L${x},${cardHeight}`
        );
      };

      const updateLineConnectingPath = (x, y) => {
        // Create a path from the origin to the plane's position
        setLineConnectingPath(`M0,${cardHeight} L${x},${y}`);
      };

      return () => {
        if (planeAnimationRef.current) planeAnimationRef.current.kill();
        if (trailAnimationRef.current) trailAnimationRef.current.kill();
        if (danceAnimationRef.current) danceAnimationRef.current.kill();
      };
    } else {
      trailAnimationRef.current.kill();
      planeAnimationRef.current.kill();
      setShadedPath("");
    }
  }, [
    cardWidth,
    cardHeight,
    danceAnimationRef,
    animationPaused,
    flewAway,
    canAnimate,
  ]);

  const startAnimation = (timeToTake) => {
    if (canAnimate) {
      setPlaneVisible(true);
      setAnimationPaused(false);
      setTimeTake(timeToTake);
      setTimeout(() => {
        planeAnimationRef.current.play(0);
        trailAnimationRef.current.play(0); // Start both plane and trail
      }, 1000);
    }
  };

  const stopAnimation = () => {
    setAnimationPaused(true);
    setFlewAway(true);
    planeAnimationRef.current.pause();
    trailAnimationRef.current.pause(); // Pause both plane and trail
  };

  //end of plane animation**********************************

  // Initialising constants and values
  const [planePosition, setPlanePosition] = useState({ x: 0, y: 180 });
  const [changes, setChanges] = useState({ dx: 0, dy: 0 });
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });
  const [running, setRunning] = useState(true);
  const canvasRef = useRef(null);
  const [scale, setScale] = useState(1);
  var [increaseMultiplier] = useState(true);
  const [hitBoundary, setHitBoundary] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [currentGameId, setGameId] = useState("");
  const [crashHistory, setCrashHistory] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [showYScale, setShowYScale] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  //handle alerts
  const [alertProps, setAlertProps] = useState({
    show: false,
    variant: "danger",
    header: "",
    message: "",
    autoDismiss: false,
  });

  const [showImage, setShowImage] = useState(true);

  // Initialize state to keep track of progress percentage
  const [progress, setProgress] = useState(100);
  const [isRunning, setIsRunning] = useState(false);

  const handleShowAlert = (variant, header, message, autoDismiss) => {
    setAlertProps({
      show: true,
      variant: variant,
      header: header,
      message: message,
      autoDismiss: autoDismiss,
    });
  };

  const handleCloseAlert = () => {
    setAlertProps((prevProps) => ({
      ...prevProps,
      show: false,
    }));
  };

  //************************************bet constants sections***************************************** */
  //button one one state
  const [isBetRunning, setIsBetRunning] = useState(false);
  const [isGameActive, setIsGameActive] = useState(false);
  const [isCashoutAllowed, setIsCashoutAllowed] = useState(false);
  //button two state
  const [isBetRunning2, setIsBetRunning2] = useState(false);
  const [isGameActive2, setIsGameActive2] = useState(false);
  const [isCashoutAllowed2, setIsCashoutAllowed2] = useState(false);
  const [crashLevel, setCrashLevel] = useState(0);
  const [maxCrashLevel, setMaxCrashLevel] = useState(20);
  const crashLevelRef = useRef(crashLevel);
  const isBetRunningRef = useRef(isBetRunning);
  const isGameActiveRef = useRef(isGameActive);
  const isBetRunningRef2 = useRef(isBetRunning2);
  const isGameActiveRef2 = useRef(isGameActive2);
  const isCashoutAllowedRef = useRef(isCashoutAllowed);
  const isCashoutAllowedRef2 = useRef(isCashoutAllowed2);
  const [hasNextRound, setHasNextRound] = useState(false);
  const hasNextRoundRef = useRef(hasNextRound);
  const [hasNextRound2, setHasNextRound2] = useState(false);
  const hasNextRoundRef2 = useRef(hasNextRound2);

  //components hooks
  const [activeTab, setActiveTab] = useState("Manual");
  const [betAmount, setBetAmount] = useState(10);
  const [autoCashOut, setAutoCashOut] = useState(false);
  const [cashOutMultiplier, setCashOutMultiplier] = useState(2.0);

  const [activeTab2, setActiveTab2] = useState("Manual");
  const [betAmount2, setBetAmount2] = useState(10);
  const [autoCashOut2, setAutoCashOut2] = useState(false);
  const [cashOutMultiplier2, setCashOutMultiplier2] = useState(2.0);
  const [disableStartButton, setDisableStartButton] = useState(false);
  const [haveDeducted, setHaveDeducted] = useState(false);

  //************************************end of bet constants sections************************************ */
  const [seed, setSeed] = useState("");
  const [seed2, setSeed2] = useState("");
  const [cashoutState, setCashoutState] = useState(false);
  const [canCallApi, setCanCallApi] = useState(true);

  const setIsBetRunningWithRef = (value) => {
    setIsBetRunning(value);
    isBetRunningRef.current = value;
  };

  const setIsBetRunningWithRef2 = (value) => {
    setIsBetRunning2(value);
    isBetRunningRef2.current = value;
  };

  const toggleCashoutState = (value) => {
    setCashoutState(value);
  };

  // Method to show the element
  const showCountDownElement = () => {
    setIsVisible(true);
  };

  // Sync ref with state whenever state changes

  useEffect(() => {
    const fetchStatus = async () => {
      console.log("updatings settings, game is", isGameActive);
      console.log("monitor running");
      try {
        const response = await fetch(
          "https://heavywins.com/api/v1/server/crashmax"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        if (!isNaN(data)) {
          const max_crash_level = data || 20;

          setMaxCrashLevel(max_crash_level);

          // console.log("max crash level set to", max_crash_level);
        }
      } catch (error) {
        console.error("Error monitoring status:", error);
      }
    };
    fetchStatus();
  }, [isGameActive]);

  useEffect(() => {
    const triggerServerRestart = async () => {
      if (isGameActive) {
        if (!isNaN(crashLevel)) {
          const currentCrashReading = crashLevel;

          if (!isNaN(crashLevel) && !isNaN(maxCrashLevel)) {
            if (crashLevel > maxCrashLevel && crashLevel > 10) {
              // Perform actions when current multiplier exceeds max crash level

              try {
                // Replace with your actual Laravel server API endpoint
                const response = await fetch(
                  `https://heavywins.com/api/v1/server/restart?crashLevel=${crashLevel}`,
                  {
                    method: "GET",
                  }
                );
                if (!response.ok) {
                  throw new Error("Network response was not ok");
                }
                const data = await response.json();
                console.log("Server restart response:", data);
              } catch (error) {
                console.error("Error triggering server restart:", error);
              }
            }
            // Example: Trigger a warning or take appropriate action
          }
        }
      }
    };
    triggerServerRestart();
  }, [isGameActive, crashLevel, maxCrashLevel]);

  useEffect(() => {
    if (betAmount < 10) {
      setBetAmount(10);
    } else {
      const newRoundedValue = Math.floor(betAmount);
      if (newRoundedValue !== betAmount) {
        if (userDataMain.authenticated) {
          const userbal = userDataMain.userData.balance;
          //check user bal
          if (newRoundedValue > userbal) {
            setBetAmount(10);
          } else {
            setBetAmount(newRoundedValue);
          }
        } else {
          setBetAmount(10);
        }
      }
    }
  }, [betAmount, userDataMain]);

  useEffect(() => {
    if (betAmount2 < 10) {
      setBetAmount2(10);
    } else {
      const newRoundedValue = Math.floor(betAmount2);
      if (newRoundedValue !== betAmount2) {
        if (userDataMain.authenticated) {
          const userbal = userDataMain.userData.balance;
          //check user bal
          if (newRoundedValue > userbal) {
            setBetAmount2(10);
          } else {
            setBetAmount2(newRoundedValue);
          }
        } else {
          setBetAmount2(10);
        }
        setBetAmount2(newRoundedValue);
      }
    }
  }, [betAmount2, userDataMain]);

  useEffect(() => {
    isGameActiveRef.current = isGameActive;
  }, [isGameActive]);

  useEffect(() => {
    isGameActiveRef2.current = isGameActive2;
  }, [isGameActive2]);

  useEffect(() => {
    isCashoutAllowedRef.current = isCashoutAllowed;
  }, [isCashoutAllowed]);

  useEffect(() => {
    isCashoutAllowedRef2.current = isCashoutAllowed2;
  }, [isCashoutAllowed2]);

  useEffect(() => {
    hasNextRoundRef.current = hasNextRound;
  }, [hasNextRound]);

  useEffect(() => {
    hasNextRoundRef2.current = hasNextRound2;
  }, [hasNextRound2]);

  useEffect(() => {
    crashLevelRef.current = crashLevel;
  }, [crashLevel]);

  // Function to update state and ref simultaneously
  const setCashoutAllowedState = (value) => {
    setIsCashoutAllowed(value);
    isCashoutAllowedRef.current = value;
  };

  const setCashoutAllowedState2 = (value) => {
    setIsCashoutAllowed2(value);
    isCashoutAllowedRef2.current = value;
  };

  const setHasNextRoundState = (value) => {
    setHasNextRound(value);
    hasNextRoundRef.current = value;
  };

  const setHasNextRoundState2 = (value) => {
    setHasNextRound2(value);
    hasNextRoundRef2.current = value;
  };

  const setCrashLevelState = (value) => {
    setCrashLevel(value);
    crashLevelRef.current = value;
  };

  ////setGameActiveState(false);
  const setGameActiveState = (value) => {
    setIsGameActive(value);
    isGameActiveRef.current = value;
  };
  const setGameActiveState2 = (value) => {
    setIsGameActive2(value);
    isGameActiveRef2.current = value;
  };

  // Method to hide the element
  const hideCountDownElement = () => {
    setIsVisible(false);
  };

  //progress bar logic
  useEffect(() => {
    if (!isRunning) return;

    // Duration for the countdown (in milliseconds)
    const duration = 2000;
    // Interval for updating the progress (e.g., 100ms)
    const interval = 10;
    // Total number of steps based on duration and interval
    const steps = duration / interval;

    let currentStep = 0;

    const timer = setInterval(() => {
      currentStep += 1;
      setProgress(100 - currentStep * (100 / steps));

      if (currentStep >= steps) {
        clearInterval(timer);
        setIsRunning(false); // Reset the running state when countdown is complete
      }
    }, interval);

    // Cleanup interval on component unmount or when isRunning changes
    return () => clearInterval(timer);
  }, [isRunning]);

  //start count down
  const startCountdown = () => {
    setProgress(100); // Reset progress to 100%
    setIsRunning(true); // Start the countdown
  };
  // Setting canvas size (width and height) also set fixed scale
  useEffect(() => {
    const handleConnect = () => {
      setIsConnected(true); // Update state when connected
      setLoading(false); // Set loading to false when connected
    };
    const handleDisconnect = () => {
      setIsConnected(false); // Update state when disconnected
      setLoading(false); // Set loading to false on disconnect
    };

    const handleInitialCrashHistory = (initialHistory) => {
      setLoading(false);
      setCrashHistory(initialHistory);
    };

    const handleUpdatedCrashHistory = (latestCrashHistory) => {
      setLoading(false);
      setCrashHistory(latestCrashHistory);
    };

    const gameIdInit = (gameIdValue) => {
      let gameId = sessionStorage.getItem("gameId");
      if (gameId) {
        sessionStorage.setItem("gameId", gameIdValue);
      } else {
        sessionStorage.setItem("gameId", gameIdValue);
      }
      setGameId(gameIdValue);
    };

    const handleGameProgress = (gameId) => {
      setGameId(gameId);
      gameIdInit(gameId);
    };

    const handleGameCrashedMain = (message) => {
      const multiplierElement = document.getElementById("multiplier");
      console.log("just received crashed event");
      const showNextRound = () => {
        setPlaneVisible(true);
        setTimeout(() => {
          if (multiplierElement) {
            multiplierElement.textContent = "Wait For Next Round";
            multiplierElement.style.fontSize = "24px";
            showCountDownElement();
            startCountdown();
          }
        }, 5000);
      };
      setLoading(false);
      if (multiplierElement) {
        multiplierElement.style.color = "red";
      }
      stopAnimation();
      setTimeout(() => {
        if (canAnimate) {
          setShowImage(false);
        }
      }, 1000);
      var newX = planePosition.x + 10;
      var newY = planePosition.y - 10;
      setPlanePosition({ x: newX, y: newY });
      setGameActiveState(false);
      setGameActiveState2(false);
      increaseMultiplier = false;
      //show flew away message
      var responseMessage = message.message;
      var latestcrashes = message.latestcrashes;
      setTimeout(() => {
        if (multiplierElement) {
          multiplierElement.style.fontSize = "24px";
          multiplierElement.textContent = responseMessage;
          setCrashLevel(0);
        }
      }, 3000);
      //reset bet btn

      if (hasNextRoundRef.current) {
        manageBetButton("cancel");
        startNewBet();
      } else {
        //check if i had bet
        manageBetButton("reset");

        if (isBetRunningRef.current) {
          onCrashedGamePayments(message.crashValue);
          console.log("requested deductions");
        }
      }

      if (hasNextRoundRef2.current) {
        manageBetButton2("cancel");
        startNewBet2();
      } else {
        manageBetButton2("reset");
        if (isBetRunningRef2.current) {
          console.log("requested deductions 2");
          onCrashedGamePayments(message.crashValue);
        }
      }
      // Stop adding dots
      stopAddingDots();

      //show next round
      showNextRound();
      //show latest crashes
    };

    socket.on("connect", handleConnect);
    socket.on("gameProgress", handleGameProgress);
    socket.on("newGameId", handleGameProgress); //newGameId
    socket.on("initialCrashHistory", handleInitialCrashHistory);
    socket.on("updatedCrashHistory", handleUpdatedCrashHistory);
    socket.on("disconnect", handleDisconnect);
    //testEmit
    socket.on("gameCrashed", handleGameCrashedMain);

    // Set disconnect callback to handle disconnections
    socketManager.setDisconnectCallback(handleDisconnect);

    // Check initial connection status
    setIsConnected(socketManager.isConnected());

    // Cleanup event listeners on component unmount
    return () => {
      socket.off("connect", handleConnect);
      socket.off("initialCrashHistory", handleInitialCrashHistory);
      socket.off("updatedCrashHistory", handleUpdatedCrashHistory);
      socket.off("disconnect", handleDisconnect);
    };
  }, [socket, location.key]);

  const stopAddingDots = () => {
    setShowYScale(false);
    setIsScrolling(false);
  };

  // Handles game state and changes and listen to sockets

  useEffect(() => {
    const multiplierElement = document.getElementById("multiplier");
    //multiplier update
    var updateMultiplier = (multipleValue) => {
      if (increaseMultiplier) {
        multiplierElement.style.fontSize = "48px";
        multiplierElement.textContent = multipleValue;
      }
    };

    //handle game states(changes);
    const handleGameState = (gameState) => {
      if (userDataMain.authenticated) {
        const userbal = userDataMain.userData.balance;
        if (betAmount > userbal) {
          setBetAmount(10);
        }
        if (betAmount2 > userbal) {
          setBetAmount2(10);
        }
      }
      setLoading(false);
      setChanges(gameState.changes);
      setRunning(gameState.running);
      const haveBetString = sessionStorage.getItem("haveBet") || "false";
      const haveBetString2 = sessionStorage.getItem("haveBet2") || "false";
      //persists on colors of btns
      if (
        isGameActive &&
        haveBetString !== "true" &&
        !hasNextRound &&
        !isBetRunning &&
        !hasNextRoundRef.current
      ) {
        manageBetButton("reset");
      }

      //persists on colors of btn2
      if (
        isGameActive2 &&
        haveBetString2 !== "true" &&
        !hasNextRound2 &&
        !isBetRunning2 &&
        !hasNextRoundRef2.current
      ) {
        manageBetButton2("reset");
      }
      //smallet y = 149 every position should be creater than this
      var crashPoint = gameState.crashPoint;
      setCrashLevelState(crashPoint);

      if (isBetRunning2) {
        userHasBet2(crashPoint);
      } else {
        if (hasNextRoundRef2.current) {
          // Initialize the new bet
          manageBetButton2("cancel");
        }
      }

      if (isBetRunning) {
        userHasBet(crashPoint);
      } else {
        if (hasNextRoundRef.current) {
          // Initialize the new bet
          manageBetButton("cancel");
        }
      }

      if (autoCashOut) {
        if (
          crashPoint >= cashOutMultiplier &&
          crashPoint > 1 &&
          cashOutMultiplier !== undefined &&
          !Number.isNaN(cashOutMultiplier) &&
          !Number.isNaN(crashPoint)
        ) {
          //initiate cashout
          if (isBetRunning) {
            if (!hasNextRoundRef.current) {
              onCashoutGamePayments();
            }
          }
        }
      }

      if (autoCashOut2) {
        if (
          crashPoint >= cashOutMultiplier2 &&
          crashPoint > 1 &&
          cashOutMultiplier2 !== undefined &&
          !Number.isNaN(cashOutMultiplier2) &&
          !Number.isNaN(crashPoint)
        ) {
          //initiate cashout
          if (isBetRunning2) {
            if (!hasNextRoundRef2.current) {
              onCashoutGamePayments2();
            }
          }
        }
      }

      if (crashPoint > 3) {
        //set multiplier value
        //alert(crashPoint);
        setIsScrolling(true);
      } else {
        setIsScrolling(false);
      }
      updateMultiplier(crashPoint + "X");
      if (hitBoundary) {
        setHitBoundary(false);
      }
    };

    //handles game Restart
    //generate a new seed for the game
    const generateSeed = () => {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          const r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    };

    const updateOrCreateGameId = (gameIdValue) => {
      let gameId = sessionStorage.getItem("gameId");
      if (gameId) {
        sessionStorage.setItem("gameId", gameIdValue);
      } else {
        sessionStorage.setItem("gameId", gameIdValue);
      }
      setGameId(gameIdValue);
    };

    const handleRestartGame = (message) => {
      // setIsBetRunningWithRef(false);
      setLoading(false);
      toggleCashoutState(true);
      if (userDataMain.authenticated) {
        const userbal = userDataMain.userData.balance;
        if (betAmount > userbal) {
          setBetAmount(10);
        }
        if (betAmount2 > userbal) {
          setBetAmount2(10);
        }
      }
      //setup game seeds
      setSeed(generateSeed());
      setSeed2(generateSeed());
      //end of seeds
      setCrashLevelState(0);
      if (hasNextRoundRef.current) {
        setIsBetRunningWithRef(true);
      }
      if (hasNextRoundRef2.current) {
        setIsBetRunningWithRef2(true);
      }
      multiplierElement.style.color = "white";
      //start graph

      startAnimation(Math.ceil(message.taketime));
      updateOrCreateGameId(message.gameId);
      //disable any
      setHasNextRoundState(false);
      setHasNextRoundState2(false);
      //hide countdown
      hideCountDownElement();
      //setGameActiveState
      setGameActiveState(true);
      setGameActiveState2(true);
      setShowYScale(true);
      //show image
      if (canAnimate) {
        setShowImage(true);
      }

      setScale(1);
      setHitBoundary(false);
      //activate crached once
      canSendRequestRef.current = true;
      canSendRequestRef2.current = true;

      //check if user has bet
      userHasBet(1);
      userHasBet2(1);
    };

    //show next round
    var showNextRound = () => {
      setPlaneVisible(true);
      setTimeout(() => {
        multiplierElement.textContent = "Wait For Next Round";
        multiplierElement.style.fontSize = "24px";
        showCountDownElement();
        startCountdown();
      }, 5000);
    };

    //handle game crashing
    var handleGameCrashed = (message) => {
      console.log("just received crashed event");
      setLoading(false);
      multiplierElement.style.color = "red";
      stopAnimation();
      if (canAnimate) {
        setTimeout(() => {
          setShowImage(false);
        }, 1000);
      }
      var newX = planePosition.x + 10;
      var newY = planePosition.y - 10;
      setPlanePosition({ x: newX, y: newY });
      setGameActiveState(false);
      setGameActiveState2(false);
      increaseMultiplier = false;
      //show flew away message
      var responseMessage = message.message;
      var latestcrashes = message.latestcrashes;
      setTimeout(() => {
        multiplierElement.style.fontSize = "24px";
        multiplierElement.textContent = responseMessage;
        setCrashLevel(0);
      }, 3000);
      //reset bet btn
      manageBetButton("reset");
      manageBetButton2("reset");
      if (hasNextRoundRef.current) {
        startNewBet();
      } else {
        //check if i had bet

        if (isBetRunning) {
          //hasDeducted(true)
          setIsBetRunningWithRef(false);
          onCrashedGamePayments(message.crashValue);
        }
      }

      if (hasNextRoundRef2.current) {
        startNewBet2();
      } else {
        if (isBetRunning2) {
          setIsBetRunningWithRef2(false);
          onCrashedGamePayments(message.crashValue);
        }
      }
      // Stop adding dots
      stopAddingDots();

      //show next round
      showNextRound();
      //show latest crashes
    };

    //listen to sockets emits
    socket.on("gameState", handleGameState);
    socket.on("restartGame", handleRestartGame);
    //socket.on("gameCrashed", handleGameCrashed);

    return () => {
      socket.off("gameState", handleGameState);
      socket.off("restartGame", handleRestartGame);
    };
  }, [
    canvasSize.width,
    autoCashOut,
    betAmount,
    crashLevel,
    cashOutMultiplier,
    canvasSize.height,
    scale,
    planePosition,
    hitBoundary,
    socket,
  ]);

  const getCrashColor = (crash) => {
    if (crash < 1.2) {
      return "#FF5733"; // Red
    } else if (crash >= 1.2 && crash < 1.5) {
      return "#FFA500"; // Orange
    } else if (crash >= 1.5 && crash < 1.8) {
      return "#FFFF00"; // Yellow
    } else if (crash >= 1.8 && crash < 2) {
      return "#7FFF00"; // Chartreuse
    } else if (crash >= 2 && crash < 2.3) {
      return "#00FF00"; // Lime
    } else if (crash >= 2.3 && crash < 2.6) {
      return "#00CED1"; // Dark Turquoise
    } else if (crash >= 2.6 && crash < 2.9) {
      return "#1E90FF"; // Dodger Blue
    } else if (crash >= 2.9 && crash < 3.2) {
      return "#8A2BE2"; // Blue Violet
    } else if (crash >= 3.2 && crash < 3.5) {
      return "#FF1493"; // Deep Pink
    } else {
      return "#FFD700"; // Gold (default or any other color for values beyond the defined ranges)
    }
  };

  useEffect(() => {
    if (canAnimate) {
      console.log("Animation can start.");
      // Insert your animation logic here
    } else {
      console.log("Animation stopped.");
      setShowImage(false);
      // Insert logic to stop the animation
    }

    return () => {
      console.log(
        "Cleanup if needed when canAnimate changes or component unmounts."
      );
      // Cleanup logic if necessary
    };
  }, [canAnimate]);

  //****************************start betting section logics***********/

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleBetAmountChange = (amount) => {
    if (betAmount + amount >= 0) {
      const storedBet = isBetRunningRef.current;
      if (storedBet !== null && storedBet !== true) {
        setBetAmount(betAmount + amount);
      }
    }
  };

  const handleQuickAmountClick = (amount) => {
    const storedBet = isBetRunningRef.current;
    if (storedBet !== null && storedBet !== true) {
      setBetAmount(amount);
    }
  };

  const handleCashOutChange = () => {
    setAutoCashOut((prevAutoCashOut) => !prevAutoCashOut);
  };

  useEffect(() => {
    const betButton = document.getElementById("create-bet-amt");
    betButton.innerText = `BET ${Math.floor(betAmount)} `;
  }, [betAmount]);

  useEffect(() => {
    const betButton2 = document.getElementById("create-bet-amt2");
    betButton2.innerText = `BET ${Math.floor(betAmount2)} `;
    betButton2.style.color = "black";
  }, [betAmount2]);

  const handleBetAmountEdit = (event) => {
    const value = parseFloat(event.target.innerText);
    if (!isNaN(value) && value >= 0) {
      const storedBet = isBetRunningRef.current;
      if (storedBet !== null && storedBet !== true) {
        if (userDataMain.authenticated) {
          const userbal = userDataMain.userData.balance;
          if (value > userbal) {
            setBetAmount(10);
          } else {
            setBetAmount(value);
          }
        } else {
          setBetAmount(10);
        }
      }
    } else {
      event.target.innerText = betAmount;
    }
  };

  //second btns setup
  const handleTabClick2 = (tab) => {
    setActiveTab2(tab);
  };

  const handleBetAmountChange2 = (amount) => {
    console.log("hello", isBetRunningRef2.current);
    if (isBetRunningRef2.current === false) {
      const sum = betAmount2 + amount;
      console.log(sum);
      if (sum >= 0) {
        setBetAmount2(sum);
      }
    }
  };

  const handleQuickAmountClick2 = (amount) => {
    const storedBet2 = isBetRunningRef2.current;
    if (storedBet2 !== null && storedBet2 !== true) {
      setBetAmount2(amount);
    }
  };

  const handleCashOutChange2 = () => {
    setAutoCashOut2(!autoCashOut2);
  };

  const handleBetAmountEdit2 = (event) => {
    const value = parseFloat(event.target.innerText);
    if (!isNaN(value) && value >= 0) {
      const storedBet2 = isBetRunningRef2.current;
      if (storedBet2 !== null && storedBet2 !== true) {
        if (userDataMain.authenticated) {
          const userbal = userDataMain.userData.balance;
          if (value > userbal) {
            setBetAmount2(10);
          } else {
            setBetAmount2(value);
          }
        } else {
          setBetAmount2(10);
        }
      }
    } else {
      event.target.innerText = betAmount2;
    }
  };

  //end of btns

  //emit user bets to server using websockets
  const handleUserBetRequest = (amount, type, btn) => {
    console.log("sending request....");
    const gameId = sessionStorage.getItem("gameId") || currentGameId;
    const phone = userDataMain.userData.phone;
    const gamehash = phone + gameId;
    const userBetdata = { phone, gameId, amount, gamehash, type, btn };
    socket.emit("userBet", userBetdata);
  };

  //function to check if user bet on game start
  const userHasBet = (crashPoint) => {
    if (isBetRunning) {
      if (!hasNextRoundRef.current) {
        const betButton = document.getElementById("create-bet-amt");
        betButton.style.backgroundColor = "yellow";
        betButton.innerText = `CASHOUT KES.${(betAmount * crashPoint).toFixed(
          2
        )}`;
      }

      setCashoutAllowedState(true);
    } else {
      setCashoutAllowedState(false);
    }
  };

  const userHasBet2 = (crashPoint) => {
    if (isBetRunning2) {
      if (!hasNextRoundRef2.current) {
        const betButton2 = document.getElementById("create-bet-amt2");
        betButton2.style.backgroundColor = "yellow";
        betButton2.innerText = `CASHOUT KES.${(betAmount2 * crashPoint).toFixed(
          2
        )}`;
      }

      setCashoutAllowedState2(true);
    } else {
      setCashoutAllowedState2(false);
    }
  };

  // Function to handle payments when the game crashes
  const onCrashedGamePayments = (crash_point) => {
    console.log("onCrashedGamePayments it");
    // Check if a bet is currently running sendCrashedRequest2
    if (isBetRunningRef2.current) {
      console.log("calling  it sendCrashedRequest2");
      // Deactivate the running bet
      setIsBetRunningWithRef2(false);
      setIsBetRunning2(false);
      // The user played and lost
      sendCrashedRequest2(crash_point, seed);
      //deactivate cashing out
      setCashoutAllowedState2(false);
    }
    if (isBetRunningRef.current) {
      console.log("calling  it sendCrashedRequest");
      // The user played and lost
      // Deactivate the running bet
      setIsBetRunningWithRef(false);
      setIsBetRunning(false);
      sendCrashedRequest(crash_point, seed2);

      //deactivate cashing out
      setCashoutAllowedState(false);
    }
    // Handle any additional payments logic when the game crashes
    //start a pending flight
    if (hasNextRoundRef.current) {
      // Initialize the new bet
      startNewBet();
    }
    if (hasNextRoundRef2.current) {
      // Initialize the new bet
      startNewBet2();
    }

    setIsBetRunningWithRef(false);
    setIsBetRunningWithRef2(false);
  };

  const sendCrashedRequest = async (crash_point, seed) => {
    console.log("at work");
    setIsBetRunning(false);
    setIsBetRunningWithRef(false);
    // Ensure betAmount is parsed as an integer
    const betspane = document.getElementById("betamount_span");
    const buttonText = betspane?.innerText ?? betAmount; // Retrieves the current text content, e.g., "BET 100"

    // Extract the number from the text using regular expressions
    const numberOnly = parseInt(buttonText.match(/\d+/)[0]);
    let amount = numberOnly;
    let status = "lost";
    let returns = 0;

    if (isNaN(amount)) {
      amount = betAmount;
    }

    // Check and block further API calls

    setIsBetRunningWithRef(false);

    canSendRequestRef.current = false;
    const storedBet = sessionStorage.getItem("haveBet"); // if null ;

    if (storedBet === "true") {
      fetch(`${API_BASE_URL}/api/v1/auth/crashed`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ amount, crash_point, status, returns, seed }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {})
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          // Optionally reset the flag after request completes
        });
    } else {
    }
    sethaveBet(false);

    // Make the API call without awaiting response
  };

  const sendCrashedRequest2 = async (crash_point, seed) => {
    // Ensure betAmount is parsed as an integer
    const betspane2 = document.getElementById("betamount_span2");
    const buttonText2 = betspane2.innerText ?? betAmount;
    // Retrieves the current text content, e.g., "BET 100"

    // Extract the number from the text using regular expressions
    const numberOnly = parseInt(buttonText2.match(/\d+/)[0]);

    let amount = numberOnly;
    let status = "lost";
    let returns = 0;

    if (isNaN(amount)) {
      amount = betAmount2;
    }

    setIsBetRunningWithRef2(false);

    canSendRequestRef2.current = false;
    const storedBet2 = sessionStorage.getItem("haveBet2"); // if null ;

    if (storedBet2 === "true") {
      fetch(`${API_BASE_URL}/api/v1/auth/crashed`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ amount, crash_point, status, returns, seed }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {})
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {});
    } else {
    }
    sethaveBet2(false);

    // Make the API call without awaiting response
  };

  const sendCashedRequest = async () => {
    setIsBetRunningWithRef(false);
    sethaveBet(false);
    manageBetButton("reset");
    //get
    const currenCrashReading = document
      .getElementById("multiplier")
      .innerText.replace("X", "");

    const crash_point = parseFloat(currenCrashReading, 1.1);
    var amount = parseInt(betAmount, 10);
    var status = "won";
    var returns = amount * crash_point;

    const gameId = sessionStorage.getItem("gameId") || currentGameId;
    const phone = userDataMain.userData.phone;
    const gamehash = phone + gameId;

    //call server with data
    try {
      await fetch(`${API_BASE_URL}/api/v1/auth/cashed`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: amount,
          crash_point: crash_point,
          status: status,
          returns: returns,
          btn: "1",
          gamehash: gamehash,
        }),
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const sendCashedRequest2 = async () => {
    setIsBetRunningWithRef2(false);
    sethaveBet2(false);
    manageBetButton2("reset");
    //get
    const currenCrashReading = document
      .getElementById("multiplier")
      .innerText.replace("X", "");

    const crash_point = parseFloat(currenCrashReading);
    var amount = parseInt(betAmount2);
    var status = "won";
    var returns = amount * crash_point;

    console.log(amount, crash_point);

    //call server with data
    try {
      const response = await fetch(`${API_BASE_URL}/api/v1/auth/cashed`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: amount,
          crash_point: crash_point,
          status: status,
          returns: returns,
          btn: "2",
        }),
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Function to handle payments when the user cashed out
  const onCashoutGamePayments = () => {
    //reset btn
    manageBetButton("reset");
    // Handle user cashout logic
    if (isCashoutAllowed && isGameActive) {
      sendCashedRequest();
    } else {
      //cancel user bet
    }
    // Stop the bet (simulate jumping out of the plane)

    setIsBetRunningWithRef(false);
    setCashoutAllowedState(false);
  };
  const onCashoutGamePayments2 = () => {
    //reset btn
    manageBetButton2("reset");
    // Handle user cashout logic
    if (isCashoutAllowed2) {
      sendCashedRequest2();
    } else {
      //cancel user bet
    }
    // Stop the bet (simulate jumping out of the plane)
    setIsBetRunningWithRef2(false);
    setCashoutAllowedState2(false);
  };

  const manageBetButton = (action) => {
    //activate canCancel state
    if (action === "cancel") {
      const betButton = document.getElementById("create-bet-amt");
      betButton.style.backgroundColor = "red";
      betButton.innerText = "CANCEL";
    } else {
      const betButton = document.getElementById("create-bet-amt");
      if (betButton) {
        betButton.style.backgroundColor = "#4ade80";
        betButton.innerText = `BET ${betAmount} `;
      }
    }
  };

  const manageBetButton2 = (action) => {
    //activate canCancel state
    if (action === "cancel") {
      const betButton2 = document.getElementById("create-bet-amt2");
      betButton2.style.backgroundColor = "red";
      betButton2.innerText = "CANCEL";
    } else {
      const betButton2 = document.getElementById("create-bet-amt2");
      if (betButton2) {
        betButton2.style.backgroundColor = "#4ade80";
        betButton2.innerText = `BET ${betAmount2} `;
      }
    }
  };

  // Function to initialize a new bet
  const startNewBet = () => {
    const userBalance = userDataMain.userData.balance;
    if (
      userBalance !== undefined &&
      userBalance !== null &&
      betAmount !== null &&
      betAmount !== undefined
    ) {
      if (betAmount <= userBalance) {
        // No game is running, so start a new bet
        setHaveDeducted(false);
        setIsBetRunningWithRef(true);
        sethaveBet(true);
        setHasProcessed(false);
        handleUserBetRequest(betAmount, "normal", 1);
        //initiate a backend bet
        startBetOnBackend(1);
        //show cancel btn as we wait for countup
        manageBetButton("cancel");
      } else {
        handleShowAlert(
          "warning",
          "Low Account Balance",
          `Deposit To Start Playing`,
          true
        );
      }
    }
  };

  const startBetOnBackend = async (btn) => {
    try {
      // Define the bet data
      const betData = {
        user_phone: "254700123456", // Replace with actual user phone
        amount: 100.0, // Replace with actual bet amount
        hash: "unique-bet-hash", // Generate or replace with actual hash
        status: "pending", // Initial status
        btn: btn, // Use the btn passed as a parameter
        type: "game-type", // Replace with actual bet type
        game_id: "game-123", // Replace with actual game ID
      };

      // Send POST request to backend API
      const response = await fetch(`${API_BASE_URL}/api/v1/game/bet`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(betData), // Correctly stringify betData object
      });

      if (response.ok) {
        // Handle successful response
        const data = await response.json();
        console.log("Bet logged successfully:", data);
      } else {
        // Handle non-2xx response
        console.error("Failed to log bet:", response.status);
      }
    } catch (error) {
      // Handle error during the fetch request
      console.error("Error:", error);
    }
  };

  const startNewBet2 = () => {
    const userBalance = userDataMain.userData.balance;
    if (
      userBalance !== undefined &&
      userBalance !== null &&
      betAmount2 !== null &&
      betAmount2 !== undefined
    ) {
      if (betAmount2 <= userBalance) {
        sethaveBet2(true);
        setHasProcessed2(false);
        socket.emit("play");
        //initiate a game on backend
        startBetOnBackend(2);
        // No game is running, so start a new bet
        setIsBetRunningWithRef2(true);
        //show cancel btn as we wait for countup

        manageBetButton2("cancel");
        handleUserBetRequest(betAmount2, "normal", 2);
      } else {
        handleShowAlert(
          "warning",
          "Low Account Balance",
          `Deposit To Start Playing`,
          true
        );
      }
    }
  };

  // Function to start a bet or cash out if a bet is already running
  const startBet2 = () => {
    if (userDataMain.authenticated) {
      //userDataMain.authenticated
      if (isBetRunning2) {
        // There is an ongoing game, so the user is cashing out isCashoutAllowed
        if (!isGameActive2) {
          setHasNextRound2(false);
          setHasNextRoundState2(false);
          setIsBetRunning2(false);
          setIsBetRunningWithRef2(false);
          sessionStorage.setItem("haveBet2", false);
          manageBetButton2("reset");
          handleUserBetRequest(betAmount2, "cancel", 2);
        } else {
          onCashoutGamePayments2();
          setIsBetRunning2(false);
          setIsBetRunningWithRef2(false);
          sessionStorage.setItem("haveBet2", false);
          manageBetButton2("reset");
        }
      } else {
        if (isGameActive2) {
          const userBalance = userDataMain.userData.balance;
          if (userBalance !== undefined) {
            if (betAmount <= userBalance) {
              //check user balance first
              if (hasNextRoundRef2.current) {
                setHasNextRoundState2(false);
                manageBetButton2("reset");
              } else {
                setHasNextRoundState2(true);
                handleUserBetRequest(betAmount, "upcomming", 2);
                manageBetButton2("CANCEL");
              }
            } else {
              handleShowAlert("warning", "Balance!", "Recharge and try", true);
            }
          } else {
            handleShowAlert("warning", "Oops", "Retry", true);
          }
        } else {
          // Initialize the new bet
          startNewBet2();
        }
      }
    } else {
      //alert login
      handleShowAlert(
        "warning",
        "Not LoginedIn",
        "Login to start playing.",
        true
      );
    }
  };

  //bet two btn
  const startBet = () => {
    setCanCallApi(true);
    if (userDataMain.authenticated) {
      if (isBetRunningRef.current) {
        //user has bet
        if (!isGameActive) {
          //game not active
          setHasNextRoundState(false);
          setHasNextRound(false);
          setIsBetRunning(false);
          setIsBetRunningWithRef(false);
          sessionStorage.setItem("haveBet", false);
          manageBetButton("reset");
          handleUserBetRequest(betAmount, "cancel", 1);
          //clear bets on sessions
        } else {
          // There is an ongoing game, so the user is cashing out isCashoutAllowed
          console.log("cashout funds here");
          onCashoutGamePayments();
          setIsBetRunning(false);
          setIsBetRunningWithRef(false);
          sessionStorage.setItem("haveBet", false);
          manageBetButton("reset");
        }

        setIsBetRunningWithRef(false);
      } else {
        if (isGameActive) {
          const userBalance = userDataMain.userData.balance;
          if (userBalance !== undefined) {
            if (betAmount <= userBalance) {
              //check user balance first
              //check if already having next round
              if (hasNextRoundRef.current) {
                setHasNextRoundState(false);
                manageBetButton("reset");
                handleUserBetRequest(betAmount, "cancel", 1);
              } else {
                setHasNextRoundState(true);
                manageBetButton("CANCEL");
                handleUserBetRequest(betAmount, "upcomming", 1);
              }
            } else {
              handleShowAlert("warning", "Balance!", "Recharge and try", true);
            }
          } else {
            handleShowAlert("warning", "Oops", "Retry", true);
          }
        } else {
          // Initialize the new bet

          startNewBet();
        }
      }
    } else {
      //alert login
      handleShowAlert(
        "warning",
        "Not LoginedIn",
        "Login to start playing.",
        true
      );
    }
  };

  //check if user is loggedIn
  //******************************end of betting section***************/
  const [isPopupVisible, setPopupVisible] = useState(false);

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  const togglePopupClose = () => {
    setPopupVisible(false);
  };

  return (
    <div className="col-span-12 lg:col-span-8 md:col-span-8 p-3 ">
      <div className="flex flex-row">
        <div className="w-full">
          <Modal show={loading} />
          <div className="flex flex-row">
            <div className="w-5/6">
              <div className="show-history flex w-full overflow-x-auto">
                {[...new Set(crashHistory)].map((crash, index) => (
                  <div
                    key={index}
                    className="crash-value p-1 font-bold"
                    style={{ color: getCrashColor(crash) }}
                  >
                    {crash}x
                  </div>
                ))}
              </div>
            </div>
            <div className="w-1/6 p-0 m-0">
              <button className="history_pop_btn" onClick={togglePopup}>
                <i class="fas fa-clock text-gray-50 pr-1"></i>
                <i
                  className={`text-gray-50 fas ${
                    isPopupVisible ? "fa-caret-down" : "fa-caret-up"
                  }`}
                ></i>
              </button>
            </div>
          </div>

          <div
            className={`fixed lg:w-2/3 z-50 ${isPopupVisible ? "" : "hidden"}`}
          >
            <div className="history_card_header">
              <div className="flex flex-row">
                <div className="flex-grow">ROUND HISTORY</div>
                <div className="w-4 text-red-500">+</div>
              </div>
            </div>
            <div className=" history_card_body overflow-x-auto">
              <div className="">
                {[...new Set(crashHistory)].map((crash, index) => (
                  <div
                    key={index}
                    className="p-1 font-bold crash-item inline-block"
                    style={{
                      color: getCrashColor(crash),
                      minWidth: "5px",
                      flexBasis: "calc(100% / 3)",
                    }}
                  >
                    {crash}x
                  </div>
                ))}
              </div>
            </div>
          </div>

          {alertProps.show && (
            <CustomAlert
              handleClose={handleCloseAlert}
              variant={alertProps.variant}
              header={alertProps.header}
              message={alertProps.message}
              autoDismiss={alertProps.autoDismiss}
            />
          )}

          <div
            className="aviator-card flex flex-row"
            onClick={togglePopupClose}
          >
            <div className="canvas-card col-span-11 p-0 m-0">
              {/* canvas card with my crashgame to be right of the yAxis div */}
              {/* add my card */}

              <div
                id="game_card"
                ref={cardRef}
                className="canvas-card animated_card"
                style={{
                  borderRadius: "10px",
                  backgroundImage: "url('cloud_5.png')",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  paddingBottom: "10px",
                }}
              >
                <svg width={cardWidth} height={cardHeight}>
                  {/* Path for the plane animation */}
                  <path
                    ref={pathRef}
                    d={`M0 ${cardHeight * 0.97} Q ${cardWidth * 0.25} ${
                      cardHeight * 0.75
                    } ${cardWidth * 0.5} ${cardHeight * 0.5} Q ${
                      cardWidth * 0.75
                    } ${cardHeight * 0.25} ${cardWidth} ${cardHeight}`}
                    stroke="none"
                    strokeWidth="2"
                    fill="none"
                  />
                  {/* Shaded area indicating the plane's path */}
                  <path
                    d={shadedPath}
                    fill="rgb(117,10,32)" // Original shaded area
                    strokeWidth="1"
                  />
                  {/* Dancing shaded area */}

                  <path
                    d={dancingShadedPath}
                    fill="rgb(117,10,32)" // Ensure visibility with a strong color
                    strokeWidth="1"
                    style={{ zIndex: 2 }} // Bring to front if necessary
                  />

                  {/* Curved line from origin to plane */}
                  <path
                    d={shadedPath}
                    fill="none"
                    stroke="red" // Adjust color as needed
                    strokeWidth="1" // Adjust thickness as needed
                  />
                  <path
                    d={lineConnectingPath}
                    fill="none"
                    stroke="red" // Adjust color as needed
                    strokeWidth="3" // Adjust thickness as needed
                  />
                  {/* Curved line from origin to plane */}
                  <path
                    d={dancingShadedPath}
                    fill="none"
                    stroke="red" // Adjust color as needed
                    strokeWidth="1" // Adjust thickness as needed
                  />
                </svg>

                {/* Plane element */}
                <div
                  ref={planeRef}
                  style={{
                    width: "50px",
                    height: "100px",
                    position: "absolute",
                    display: showImage ? "flex" : "none",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 1,
                  }}
                >
                  <img
                    src="plane.png"
                    style={{
                      width: "75px",
                      marginLeft: "20px",
                      paddingLeft: "20px",
                      marginTop: "-35px", //pushed up the plane abit
                      paddingTop: "-10px",
                      display: `${canAnimate ? "" : "wuny"}`,
                    }}
                    width={{}}
                    alt="plane"
                  ></img>
                </div>

                {/* Trail element */}
                <div
                  ref={trailRef}
                  style={{
                    display: `${canAnimate ? "" : "wuny"}`,
                    width: "10px",
                    height: "10px",
                    backgroundColor: "rgba(0, 0, 255, 0.5)",
                    borderRadius: "50%",
                    position: "absolute",
                    // marginTop: "-140px", //tried to push trail up
                    zIndex: 1,
                  }}
                />
              </div>
              {/* end of my card */}
              <div id="multiplier" className="multiplier-value">
                1.0x
              </div>
              <div
                id=""
                className={`absolute top-48 left-100 w-full z-[999999999] mt-[-25] bg-transparent  h-[15px]  rounded-lg p-4 ${
                  isVisible ? "" : "hidden"
                }`}
                style={{ zIndex: 50 }} // Keep zIndex inline if needed
              >
                <progress
                  class={`progress progress-error`}
                  style={{ width: `100%` }}
                  value={`${progress}`}
                  max="100"
                ></progress>
              </div>
            </div>
          </div>
          <div
            onClick={togglePopupClose}
            className="xAxis flex flex-row justify-center"
          >
            {/* dots to represent scale */}
            {/* Loop through the number of dots */}
            <div className={`marquee mt-2`}>
              <div
                className={`marquee-content ${showYScale ? "" : "wuny"}`}
                style={{
                  animationPlayState: isScrolling ? "running" : "paused",
                }}
              >
                <div className="marq_row ">
                  {Array.from({ length: 6 * 4 }).map((_, index) => (
                    <div className="marq_col" key={index}>
                      <p className="dot bg-light"></p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-3">
        <div className="col-span-12 lg:col-span-6 md:col-span-6 sm:col-span-12 m-3">
          <BetCard
            cardType={1}
            activeTab={activeTab}
            handleTabClick={handleTabClick}
            startBet={startBet}
            betAmount={betAmount}
            autoCashOut={autoCashOut}
            handleCashOutChange={handleCashOutChange}
            cashOutMultiplier={cashOutMultiplier}
            setCashOutMultiplier={setCashOutMultiplier}
            handleBetAmountChange={handleBetAmountChange}
            handleBetAmountEdit={handleBetAmountEdit}
            handleQuickAmountClick={handleQuickAmountClick}
          />
        </div>
        <div className="col-span-12 lg:col-span-6 md:col-span-6 sm:col-span-12 m-2">
          {/* Place for second card */}
          <SecondBetCard
            activeTab2={activeTab2}
            handleTabClick2={handleTabClick2}
            startBet2={startBet2}
            betAmount2={betAmount2}
            autoCashOut2={autoCashOut2}
            handleCashOutChange2={handleCashOutChange2}
            cashOutMultiplier2={cashOutMultiplier2}
            setCashOutMultiplier2={setCashOutMultiplier2}
            handleBetAmountChange2={handleBetAmountChange2}
            handleBetAmountEdit2={handleBetAmountEdit2}
            handleQuickAmountClick2={handleQuickAmountClick2}
          />
        </div>
      </div>
    </div>
  );
};

export default Game;
